import {
  AboutUs,
  ActivateAccount,
  BorrowerPolicy,
  ContactUs,
  Dashboard,
  ExecutiveGuarantee,
  ExecutiveSuccess,
  GeneralPolicy,
  Landing,
  LenderPolicy,
  TermsAndConditions,
} from 'features/misc';
import { useAuth } from 'lib/auth';
import { useRoutes, Navigate } from 'react-router-dom';

import { protectedRoutes } from './protected';
import { publicRoutes } from './public';
import { ConfirmAccount } from 'features/auth/routes/ConfirmAccount';

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes = [
    // { path: '/', element: <Landing /> },
    { path: '/about-us', element: <AboutUs /> },
    { path: '/contact-us', element: <ContactUs /> },
    { path: '/terms-and-conditions', element: <TermsAndConditions /> },
    { path: '/lender-policy', element: <LenderPolicy /> },
    { path: '/borrower-policy', element: <BorrowerPolicy /> },
    { path: '/general-policy', element: <GeneralPolicy /> },
    { path: '/activate/:code', element: <ActivateAccount /> },
    { path: '/please-confirm-email', element: <ConfirmAccount /> },
    { path: '/executive-guarantee', element: <ExecutiveGuarantee /> },
    { path: '/executive-guarantee/success', element: <ExecutiveSuccess /> },
  ];

  const routes = auth.user ? protectedRoutes : publicRoutes;

  const element = useRoutes([...routes, ...commonRoutes]);

  return <>{element}</>;
};
