import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { loginWithFacebook } from 'features/auth/api/loginWithFacebook';

export const FacebookRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleFacebookInAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No Facebook code found in the URL.');
        navigate('/auth/login');
        return;
      }

      try {
        // Call the API with the Facebook code
        const response = await loginWithFacebook({ code });

        const user = await handleUserResponse(response);

        console.log('Facebook login successful:', user);

        navigate('/app'); // Redirect to the main app
      } catch (error) {
        console.error('Error during Facebook login:', error);
        navigate('/auth/login'); // Redirect to login page on error
      }
    };

    handleFacebookInAuth();
  }, [navigate]);

  return (
    <Layout title="Logging in with Facebook">
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg font-medium text-gray-600">
          Signing in with Facebook. Please wait...
        </p>
      </div>
    </Layout>
  );
};
