import React, { useState, useEffect } from 'react';
import * as z from 'zod';
import { Button, Spinner } from 'components/Elements';
import {
  Form,
  InputField,
  LocationField,
  ReactNumberFormatInputField2,
  ReactSelectField,
  RegularTextAreaField,
  SelectField,
} from 'components/Form';
import { Head } from 'components/Head';
import Swal from 'sweetalert2';
import { useCreateAsset } from '../api/createAsset';
import { useNavigate } from 'react-router-dom';
import { useProducts } from 'features/products';
import { dataURItoBlob } from 'utils/dataURIToBlob';
import phoneBrands from '../data/phoneBrands.json';
import phoneModels from '../data/phoneModels.json';
import { ContentLayout } from 'components/Layout';
import Breadcrumbs from 'components/Layout/Breadcrumbs';
import { ReactSelectField2, ReactSelectFieldWithInfo } from 'components/Pigeon';
import storage from 'utils/storage';
import { defaults } from '../data/defaults';
import { reverseGeocode } from 'utils/reverseGeocode';

const landSchema = z.object({
  title: z
    .string({
      required_error: 'Title of Asset is required',
      invalid_type_error: 'Title of Asset must be a string',
    })
    .min(1, { message: 'Title of Asset cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  type_of_title: z
    .string({
      required_error: 'Type of title is required',
      invalid_type_error: 'Type of title must be a string',
    })
    .min(1, { message: 'Type of title cannot be empty' }),
  size_of_land: z
    .string({
      required_error: 'Size of land is required',
      invalid_type_error: 'Size of land must be a string',
    })
    .min(1, { message: 'Size of land cannot be empty' }),
  whats_on_land: z
    .string({
      required_error: 'Whats on land is required',
      invalid_type_error: 'Whats on land must be a string',
    })
    .min(1, { message: 'Whats on land cannot be empty' }),
  specify_others: z.string().optional(),
  description: z
    .string({
      required_error: 'Asset description is required',
      invalid_type_error: 'Asset description must be a string',
    })
    .min(1, { message: 'Asset description cannot be empty' }),
});
const landfileSchema = z.object({
  imageinput0: z
    .string({
      required_error: 'Wide landscape view of land is required',
      invalid_type_error: 'Wide landscape view of land must be an image',
    })
    .min(1, { message: 'Wide landscape view of land cannot be empty' }),
  imageinput1: z
    .string({
      required_error: 'Land with access road visible is required',
      invalid_type_error: 'Land with access road visible must be an image',
    })
    .min(1, { message: 'Land with access road visible cannot be empty' }),
  imageinput2: z
    .string({
      required_error: 'Neighborhood view from land is required',
      invalid_type_error: 'Neighborhood view from land must be an image',
    })
    .min(1, { message: 'Neighborhood view from land cannot be empty' }),
  imageinput3: z
    .string({
      required_error: 'Additional picture of neighbourhood is required',
      invalid_type_error: 'Additional picture of neighbourhood must be an image',
    })
    .min(1, { message: 'Additional picture of neighbourhood cannot be empty' }),
  imageinput4: z
    .string({
      required_error: 'Markstone/boundary point on land is required',
      invalid_type_error: 'Markstone/boundary point on land must be an image',
    })
    .min(1, { message: 'Markstone/boundary point on land cannot be empty' }),
  imageinput5: z
    .string({
      required_error: 'Land title - Front page is required',
      invalid_type_error: 'Land title - Front page must be an image',
    })
    .min(1, { message: 'Land title - Front page cannot be empty' }),
  imageinput6: z
    .string({
      required_error: 'Land title - Print page is required',
      invalid_type_error: 'Land title - Print page must be an image',
    })
    .min(1, { message: 'Land title - Print page cannot be empty' }),
  imageinput7: z
    .string({
      required_error: 'Land title - Encumbrances is required',
      invalid_type_error: 'Land title - Encumbrances must be an image',
    })
    .min(1, { message: 'Land title - Encumbrances cannot be empty' }),
  imageinput8: z
    .string({
      required_error: 'Additional pages of land title is required',
      invalid_type_error: 'Additional pages of land title must be an image',
    })
    .min(1, { message: 'Additional pages of land title cannot be empty' }),
  imageinput9: z
    .string({
      required_error: 'Exterior landscape of building is required',
      invalid_type_error: 'Exterior landscape of building must be an image',
    })
    .min(1, { message: 'Exterior landscape of building cannot be empty' }),
  imageinput10: z
    .string({
      required_error: 'Living room portrait is required',
      invalid_type_error: 'Living room portrait must be an image',
    })
    .min(1, { message: 'Living room portrait  cannot be empty' }),
  imageinput11: z
    .string({
      required_error: 'Dining room portrait is required',
      invalid_type_error: 'Dining room portrait must be an image',
    })
    .min(1, { message: 'Dining room portrait cannot be empty' }),
  imageinput12: z
    .string({
      required_error: 'Kitchen portrait is required',
      invalid_type_error: 'Kitchen portrait must be an image',
    })
    .min(1, { message: 'Kitchen portrait cannot be empty' }),
  imageinput13: z
    .string({
      required_error: 'Bedroom portrait is required',
      invalid_type_error: 'Bedroom portrait must be an image',
    })
    .min(1, { message: 'Bedroom portrait cannot be empty' }),
});
const landValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
const vehiclesAssetDetailsSchema = z.object({
  title: z
    .string({
      required_error: 'number plate is required',
      invalid_type_error: 'number plate must be a string',
    })
    .min(1, { message: 'number plate cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  vehicle_sub_category: z
    .string({
      required_error: 'vehicle category is required',
      invalid_type_error: 'vehicle category must be a string',
    })
    .min(1, { message: 'vehicle category cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  tracker: z
    .string({
      required_error: 'tracker is required',
      invalid_type_error: 'tracker must be a string',
    })
    .min(1, { message: 'tracker cannot be empty' }),
  model: z
    .string({
      required_error: 'model is required',
      invalid_type_error: 'model must be a string',
    })
    .min(1, { message: 'model cannot be empty' }),
  color: z
    .string({
      required_error: 'color is required',
      invalid_type_error: 'color must be a string',
    })
    .min(1, { message: 'color cannot be empty' }),
  mileage: z
    .string({
      required_error: 'mileage is required',
      invalid_type_error: 'mileage must be a string',
    })
    .min(1, { message: 'mileage cannot be empty' }),
  parking_status: z
    .string({
      required_error: 'parking status is required',
      invalid_type_error: 'parking status must be a string',
    })
    .min(1, { message: 'parking status cannot be empty' }),
  year_of_manufacture: z
    .number({
      required_error: 'year of manufacture is required',
      invalid_type_error: 'year of manufacture must be a number',
    })
    .min(1, { message: 'year of manufacture cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
const vehiclesPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Front view (show number plate) is required',
    invalid_type_error: 'Front view (show number plate) must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Side view (both sides) is required',
    invalid_type_error: 'Side view (both sides) must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Back view is required',
    invalid_type_error: 'Back view must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Close-ups of any existing damage or unique features is required',
    invalid_type_error: 'Close-ups of any existing damage or unique features must be an image',
  }),
  imageinput4: z.string({
    required_error: "Driver's seat area is required",
    invalid_type_error: "Driver's seat area must be an image",
  }),
  imageinput5: z.string({
    required_error: 'Passenger seating area is required',
    invalid_type_error: 'Passenger seating area must be an image',
  }),
  imageinput6: z.string({
    required_error: 'Dashboard and control panel is required',
    invalid_type_error: 'Dashboard and control panel must be an image',
  }),
  imageinput7: z.string({
    required_error: 'Close-ups of odometer, indicating mileage is required',
    invalid_type_error: 'Close-ups of odometer, indicating mileage must be an image',
  }),
  imageinput8: z.string({
    required_error: 'Underside of vehicle is required',
    invalid_type_error: 'Underside of vehicle must be an image',
  }),
  imageinput9: z.string({
    required_error: 'Close-up of Wheels and Tyres is required',
    invalid_type_error: 'Close-up of Wheels and Tyres must be an image',
  }),
  imageinput10: z.string({
    required_error: 'Engine is required',
    invalid_type_error: 'Engine must be an image',
  }),
  imageinput11: z.string({
    required_error: 'Logbook is required',
    invalid_type_error: 'Logbook must be an image',
  }),
});
const vehicleValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
const smartPhoneSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  ram_size: z
    .string({
      required_error: 'RAM Size is required',
      invalid_type_error: 'RAM Size must be a string',
    })
    .min(1, { message: 'RAM Size cannot be empty' }),
  model: z
    .string({
      required_error: 'model is required',
      invalid_type_error: 'model must be a string',
    })
    .min(1, { message: 'model cannot be empty' }),
  storage_space: z
    .string({
      required_error: 'Storage Space is required',
      invalid_type_error: 'Storage Space must be a string',
    })
    .min(1, { message: 'Storage Space cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
const smartPhonePicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
const smartPhoneValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
const televisionSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  dimension: z
    .string({
      required_error: 'Dimension is required',
      invalid_type_error: 'Dimension must be a string',
    })
    .min(1, { message: 'Dimension cannot be empty' }),
  smart_tv: z.boolean({
    required_error: 'Smart Tv is required',
    invalid_type_error: 'Smart Tv must be a boolean',
  }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
const televisionPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
const televisionValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
const computersSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  brand: z
    .string({
      required_error: 'Brand is required',
      invalid_type_error: 'Brand must be a string',
    })
    .min(1, { message: 'Brand cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  ram_size: z
    .string({
      required_error: 'RAM Size is required',
      invalid_type_error: 'RAM Size must be a string',
    })
    .min(1, { message: 'RAM Size cannot be empty' }),
  processor_speed: z
    .string({
      required_error: 'Processor speed is required',
      invalid_type_error: 'Processor speed must be a string',
    })
    .min(1, { message: 'Processor speed cannot be empty' }),
  hard_disk_space: z
    .string({
      required_error: 'Hard disk Space is required',
      invalid_type_error: 'Hard disk Space must be a string',
    })
    .min(1, { message: 'Hard disk Space cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
const computerPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
const computerValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});
const otherElectronicsSchema = z.object({
  title: z
    .string({
      required_error: 'title is required',
      invalid_type_error: 'title must be a string',
    })
    .min(1, { message: 'title cannot be empty' }),
  product: z
    .string({
      required_error: 'Product is required',
      invalid_type_error: 'Product must be a string',
    })
    .min(1, { message: 'Product cannot be empty' }),
  nature_of_electronic: z
    .string({
      required_error: 'Nature of Electronic is required',
      invalid_type_error: 'Nature of Electronic must be a string',
    })
    .min(1, { message: 'Nature of Electronic cannot be empty' }),
  specify_others: z
    .string({
      invalid_type_error: 'Specify others must be a string',
    })
    .optional(),
  model_type: z
    .string({
      required_error: 'Model Type is required',
      invalid_type_error: 'Model Type must be a string',
    })
    .min(1, { message: 'Model Type cannot be empty' }),
  description: z
    .string({
      required_error: 'description is required',
      invalid_type_error: 'description must be a string',
    })
    .min(1, { message: 'description cannot be empty' }),
});
const otherElectronicsPicturesSchema = z.object({
  imageinput0: z.string({
    required_error: 'Image 1 is required',
    invalid_type_error: 'Image 1 must be an image',
  }),
  imageinput1: z.string({
    required_error: 'Image 2 is required',
    invalid_type_error: 'Image 2 must be an image',
  }),
  imageinput2: z.string({
    required_error: 'Image 3 is required',
    invalid_type_error: 'Image 3 must be an image',
  }),
  imageinput3: z.string({
    required_error: 'Image 4 is required',
    invalid_type_error: 'Image 4 must be an image',
  }),
});
const otherElectronicsValuationSchema = z.object({
  purchase_amount: z.number({
    required_error: 'purchase amount is required',
    invalid_type_error: 'purchase amount must be a number',
  }),
  current_worth: z.number({
    required_error: 'current worth is required',
    invalid_type_error: 'current worth must be a number',
  }),
  purchase_date: z.string({
    required_error: 'purchase date is required',
    invalid_type_error: 'purchase date must be a number',
  }),
});

export const CreateAsset = () => {
  const [category, setCategory] = useState('');
  const productsQuery = useProducts();
  const [assetDetails, setAssetDetails] = useState(false);
  const [pictures, setPictures] = useState(false);
  const [valuation, setValuation] = useState(false);
  const createAssetMutation = useCreateAsset();
  const [assetImages, setAssetImages] = useState(Array(8).fill(null));
  const [coordinates, setCoordinates] = useState();

  const [vehicleSubCategory, setVehicleSubCategory] = useState('private_vehicles');
  const [landLocation, setLandLocation] = useState('');
  const [landErr, setLandErr] = useState();
  const [vehicleAssetData, setvehicleAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    vehicle_sub_category: 'personal_vehicles',
    selectedVehicle_sub_category: 'personal_vehicles',
    brand: '',
    color: '',
    selectedBrand: '',
    specify_others: '',
    tracker: '',
    selectedTracker: '',
    model: '',
    selectedModel: '',
    mileage: '',
    selectedMileage: '',
    parking_status: '',
    selectedParking_status: '',
    year_of_manufacture: '',
    description: '',
  });
  const [smartPhoneAssetData, setSmartPhoneAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    ram_size: '',
    selectedram_size: '',
    model: '',
    selectedModel: '',
    storage_space: '',
    selectedstorage_space: '',
    description: '',
  });
  const [televisionAssetData, setTelevisionAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    dimension: '',
    selecteddimension: '',
    smart_tv: '',
    selectedsmart_tv: '',
    description: '',
  });
  const [computerAssetData, setComputerAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    brand: '',
    selectedBrand: '',
    specify_others: '',
    ram_size: '',
    hard_disk_type: 'HDD',
    selectedram_size: '',
    processor_speed: '',
    selectedprocessor_speed: '',
    hard_disk_space: '',
    selectedhard_disk_space: '',
    selectedhard_disk_type: 'HDD',
    description: '',
  });
  const [otherElectronicsAssetData, setOtherElectronicsAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    nature_of_electronic: '',
    selectednature_of_electronic: '',
    specify_others: '',
    model_type: '',
    description: '',
  });
  const [realEstasteAssetData, setRealEstasteAssetData] = useState({
    title: '',
    product: '',
    selectedProduct: '',
    land_location: '',
    type_of_title: '',
    selectedtype_of_title: '',
    size_of_land: '',
    selectedsize_of_land: '',
    whats_on_land: '',
    selectedswhats_on_land: '',
    specify_others: '',
    description: '',
  });
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      // setAssetDetails(true);
      // localStorage.removeItem('assetCategory')
      const aw = localStorage.getItem('assetCategory');
      if (aw) {
        setCategory(aw);
        // console.log('assetCategory', aw)
        if (aw === 'REAL_ESTATE_WITH_LAND_TITLE') {
          const aw1 = storage.getOtherValues('realEstasteAssetData');
          // console.log('realEstasteAssetData', aw1)
          setRealEstasteAssetData(aw1);
        }
        if (aw === 'VEHICLES') {
          const aw1 = storage.getOtherValues('vehicleAssetData');
          // console.log('vehicleAssetData', aw1)
          setvehicleAssetData(aw1);
        }
        if (aw === 'SMART_PHONES') {
          const aw1 = storage.getOtherValues('smartPhoneAssetData');
          // console.log('vehicleAssetData', aw1)
          setSmartPhoneAssetData(aw1);
        }
        if (aw === 'TELEVISION') {
          const aw1 = storage.getOtherValues('televisionAssetData');
          // console.log('vehicleAssetData', aw1)
          setvehicleAssetData(aw1);
        }
        if (aw === 'COMPUTERS') {
          const aw1 = storage.getOtherValues('computerAssetData');
          // console.log('vehicleAssetData', aw1)
          setComputerAssetData(aw1);
        }
        if (aw === 'OTHER_ELECTRONICS') {
          const aw1 = storage.getOtherValues('otherElectronicsAssetData');
          // console.log('vehicleAssetData', aw1)
          setOtherElectronicsAssetData(aw1);
        }
        const ad = localStorage.getItem('formStep');
        // localStorage.removeItem('pictureAssetData')
        if (ad) {
          if (ad === 'assetDetails') {
            setAssetDetails(true);
          }
          if (ad === 'pictures') {
            // localStorage.removeItem('pictureAssetData')
            // try {
            //   const data = await client.get('pictureAssetData');
            //   if (data) {
            //     setAssetImages(JSON.parse(data));
            //   }
            // } catch (error) {
            //   console.error('Failed to retrieve image data from Redis', error);
            // }
            // const dt = storage.getOtherValues('pictureAssetData')
            // if (dt) {
            //   // console.log('pictures', dt)
            //   setAssetImages(dt);
            // }
            setPictures(true);
          }
          if (ad === 'valuation') {
            setValuation(true);
          }
        } else {
          setAssetDetails(true);
        }
      }
    };
    getData();
  }, []);

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const reader = new FileReader();
        reader.onload = () => {
          const newImages = [...assetImages];
          newImages[index] = reader.result;
          setAssetImages(newImages);
        };
        reader.readAsDataURL(file);
      } catch (error) {
        console.log('image upload error', error);
      }
    }
  };

  if (productsQuery.isLoading) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  if (!productsQuery?.data) return null;

  const routes = [
    {
      name: 'Asset Center',
      path: '/app/assets',
    },
    {
      name: 'Create Asset',
      path: '/app/assets/create',
    },
  ];

  const handleLocationChange = async (newLocation) => {
    setCoordinates(newLocation);
    const addressLocation = await reverseGeocode(newLocation);
    console.log(addressLocation);
    setLandLocation(addressLocation);
  };

  return (
    <ContentLayout title="Create Asset">
      <Breadcrumbs routes={routes} />

      <div className="mt-8">
        <Head title="Create Asset" />
        <div className="grid grid-cols-1 md:grid-cols-4 gap-4 items-start">
          <div className="bg-white rounded-2xl shadow p-6 flex flex-row md:flex-col w-full">
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You will lose all your progress!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, close it!',
                    cancelButtonText: 'No, keep it',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      setCategory('');
                      setPictures(false);
                      setValuation(false);
                      setAssetDetails(false);
                    }
                  });
                }}
                className={`${!category && `bg-gray-100`} text-primary-100 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">1</span>
                <span className="hidden md:block">Choose Category</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setPictures(false);
                  setValuation(false);
                  setAssetDetails(true);
                  localStorage.setItem('formStep', 'assetDetails');
                }}
                className={`${assetDetails && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">2</span>
                <span className="hidden md:block">Asset Details</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(false);
                  setPictures(true);
                  localStorage.setItem('formStep', 'pictures');
                }}
                className={`${pictures && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">3</span>
                <span className="hidden md:block">Pictures</span>
              </button>
            </div>
            <div className="grid grid-cols-1 gap-2">
              <button
                disabled={!category}
                onClick={() => {
                  setAssetDetails(false);
                  setValuation(true);
                  setPictures(false);
                  localStorage.setItem('formStep', 'valuation');
                }}
                className={`${valuation && `bg-gray-100`} text-gray-500 p-4 rounded-2xl`}
              >
                <span className="block md:hidden">4</span>
                <span className="hidden md:block">Valuation</span>
              </button>
            </div>
          </div>
          <div className="md:col-span-3">
            {!category && (
              <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                <div className="mb-4">Choose Category</div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <button
                    onClick={() => {
                      setCategory('REAL_ESTATE_WITH_LAND_TITLE');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'REAL_ESTATE_WITH_LAND_TITLE');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Real Estate With Land Title
                  </button>
                  <button
                    onClick={() => {
                      setCategory('VEHICLES');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'VEHICLES');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Vehicles
                  </button>
                  <button
                    onClick={() => {
                      setCategory('TELEVISION');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'TELEVISION');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Television
                  </button>
                  <button
                    onClick={() => {
                      setCategory('COMPUTERS');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'COMPUTERS');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Computer
                  </button>
                  <button
                    onClick={() => {
                      setCategory('SMART_PHONES');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'SMART_PHONES');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Smart Phones
                  </button>
                  <button
                    onClick={() => {
                      setCategory('OTHER_ELECTRONICS');
                      setAssetDetails(true);
                      localStorage.setItem('assetCategory', 'OTHER_ELECTRONICS');
                    }}
                    className="border border-gray-200 rounded-md p-5"
                  >
                    Other Electronics
                  </button>
                </div>
              </div>
            )}
            {category === 'REAL_ESTATE_WITH_LAND_TITLE' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      values.land_location = landLocation;
                      values.coordinates = coordinates;
                      console.log(values);
                      console.log('land submit', values);
                      localStorage.setItem('realestateAssetDetail', JSON.stringify(values));
                      localStorage.setItem('formStep', 'pictures');
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: realEstasteAssetData.title,
                      product: realEstasteAssetData.product,
                      land_location: realEstasteAssetData.land_location,
                      type_of_title: realEstasteAssetData.type_of_title,
                      size_of_land: realEstasteAssetData.size_of_land,
                      whats_on_land: realEstasteAssetData.whats_on_land,
                      specify_others: realEstasteAssetData.specify_others,
                      description: realEstasteAssetData.description,
                    }}
                    schema={landSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for real estate</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              value={realEstasteAssetData.title}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(event) => {
                                formState.errors['title'] = '';
                                setValue('title', event.target.value);
                                const dt = { ...realEstasteAssetData, title: event.target.value };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={realEstasteAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data
                                  ?.map((p) => ({
                                    label: p.product_title,
                                    value: p._id,
                                  }))
                                  ?.find((p) => p.value === e.value);
                                setValue('product', e.value);
                                formState.errors['product'] = '';
                                const dt = {
                                  ...realEstasteAssetData,
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />
                            <LocationField
                              label="Land Location"
                              caption="Please drag the pin to the exact location of the land"
                              onChange={handleLocationChange}
                              // error={landLocationError}
                            />

                            {landLocation && (
                              <div className="bg-gray-100 p-4 rounded-md">
                                <span className="font-bold text-sm">Land Location</span>
                                <br />
                                <span>{landLocation}</span>
                              </div>
                            )}

                            <ReactSelectField2
                              label="Type of Title"
                              type="text"
                              editorContent={watch('type_of_title')}
                              name="type_of_title"
                              defaultValue={realEstasteAssetData.selectedtype_of_title}
                              error={formState.errors['type_of_title']}
                              registration={register('type_of_title')}
                              options={defaults.type_of_title}
                              onChange={async (e) => {
                                const selectedProduct = defaults.type_of_title?.find(
                                  (p) => p.value === e.value
                                );
                                setValue('type_of_title', e.value);
                                formState.errors['type_of_title'] = '';
                                const dt = {
                                  ...realEstasteAssetData,
                                  type_of_title: e.value,
                                  selectedtype_of_title: selectedProduct,
                                };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Size of Land"
                              type="text"
                              editorContent={watch('size_of_land')}
                              name="size_of_land"
                              defaultValue={realEstasteAssetData.selectedsize_of_land}
                              error={formState.errors['size_of_land']}
                              registration={register('size_of_land')}
                              options={defaults.size_of_land}
                              onChange={async (e) => {
                                const selectedProduct = defaults.size_of_land?.find(
                                  (p) => p.value === e.value
                                );
                                setValue('size_of_land', e.value);
                                formState.errors['size_of_land'] = '';
                                const dt = {
                                  ...realEstasteAssetData,
                                  size_of_land: e.value,
                                  selectedsize_of_land: selectedProduct,
                                };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="What's on the Land"
                              type="text"
                              editorContent={watch('whats_on_land')}
                              name="whats_on_land"
                              defaultValue={realEstasteAssetData.selectedswhats_on_land}
                              error={formState.errors['whats_on_land']}
                              registration={register('whats_on_land')}
                              options={defaults.whats_on_land}
                              onChange={async (e) => {
                                const selectedProduct = defaults.whats_on_land?.find(
                                  (p) => p.value === e.value
                                );
                                setValue('whats_on_land', e.value);
                                formState.errors['whats_on_land'] = '';
                                const dt = {
                                  ...realEstasteAssetData,
                                  whats_on_land: e.value,
                                  selectedswhats_on_land: selectedProduct,
                                };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />

                            {realEstasteAssetData.whats_on_land === 'OTHERS' && (
                              <InputField
                                label="Specify Others"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                name="specify_others"
                                value={realEstasteAssetData.specify_others}
                                onChange={(event) => {
                                  formState.errors['specify_others'] = '';
                                  setValue('specify_others', event.target.value);
                                  const dt = {
                                    ...realEstasteAssetData,
                                    specify_others: event.target.value,
                                  };
                                  setRealEstasteAssetData(dt);
                                  storage.setAnyFormValues('realEstasteAssetData', dt);
                                }}
                              />
                            )}
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Describe your land in detail. Share any unique features, history, or potential uses. This information will help us understand your property better and tailor our services to meet your needs."
                              name="description"
                              value={realEstasteAssetData.description}
                              onChange={(e) => {
                                formState.errors['description'] = '';
                                setValue('description', e.target.value);
                                const dt = { ...realEstasteAssetData, description: e.target.value };
                                setRealEstasteAssetData(dt);
                                storage.setAnyFormValues('realEstasteAssetData', dt);
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      console.log('land image submit', assetImages);
                      // try {
                      //   await client.set('pictureAssetData', JSON.stringify(dt));
                      // } catch (error) {
                      //   console.error('Failed to store image in Redis', error);
                      // }
                      // localStorage.setItem('realestateImageDetail', JSON.stringify(assetImages))
                      // localStorage.setItem('formStep', 'valuation')
                      setPictures(false);
                      setValuation(true);
                    }}
                    // defaultValues={{
                    //   imageinput0: assetImages?.imageinput0,
                    //   imageinput1: assetImages?.imageinput1,
                    //   imageinput2: assetImages?.imageinput2,
                    //   imageinput3: assetImages?.imageinput3,
                    //   imageinput4: assetImages?.imageinput4,
                    //   imageinput5: assetImages?.imageinput5,
                    //   imageinput6: assetImages?.imageinput6,
                    //   imageinput7: assetImages?.imageinput7,
                    //   imageinput8: assetImages?.imageinput8,
                    //   imageinput9: assetImages?.imageinput9,
                    //   imageinput10: assetImages?.imageinput10,
                    //   imageinput11: assetImages?.imageinput11,
                    //   imageinput12: assetImages?.imageinput12,
                    //   imageinput13: assetImages?.imageinput13,
                    // }}
                    // schema={landfileSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images are clear and follow the guidelines on
                          what picture angles to take.
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {[...Array(15).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                name={`imageinput${index}`}
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />

                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {index === 0 && 'Wide landscape view of land'}
                                  {index === 1 && 'Land with access road visible'}
                                  {index === 2 && 'Neighborhood view from land'}
                                  {index === 3 && 'Additional picture of neighbourhood'}
                                  {index === 4 && 'Markstone/boundary point on land'}
                                  {index === 5 && 'Land title - Front page'}
                                  {index === 6 && 'Land title - Print page'}
                                  {index === 7 && 'Land title - Encumbrances'}
                                  {index === 8 && 'Additional pages of land title'}
                                  {index === 9 && 'Exterior landscape of building'}
                                  {index === 10 && 'Living room portrait'}
                                  {index === 11 && 'Dining room portrait'}
                                  {index === 12 && 'Kitchen portrait'}
                                  {index >= 13 && `Bedroom portrait ${index - 12}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      let aw = localStorage.getItem('realestateAssetDetail');
                      let ad = localStorage.getItem('realestateImageDetail');
                      const as = localStorage.getItem('assetCategory');
                      if (!as) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (aw) {
                        aw = JSON.parse(aw);
                      } else {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      if (ad) {
                        ad = JSON.parse(ad);
                      } else {
                        if (!assetImages) {
                          setLandErr('Step 3 info missing');
                          return;
                        }
                      }
                      // console.log('submit data', aw, ad)
                      const bodyFormData = new FormData();
                      bodyFormData.append('title', aw.title);
                      bodyFormData.append('category', as);
                      bodyFormData.append('land_location', aw.land_location);
                      bodyFormData.append('coordinates', aw.coordinates);
                      bodyFormData.append('product', aw.product);
                      bodyFormData.append('type_of_title', aw.type_of_title);
                      bodyFormData.append('size_of_land', aw.size_of_land);
                      bodyFormData.append('whats_on_land', aw.whats_on_land);
                      bodyFormData.append('specify_others', aw.specify_others);
                      bodyFormData.append('description', aw.description);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('current_worth', values.current_worth);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      // console.log('land submit', values)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        ad.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      }

                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('realestateAssetDetail');
                          localStorage.removeItem('realestateImageDetail');
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('pictureAssetData');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          setAssetImages(Array(8).fill(null));
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={landValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy it at? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it?"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'VEHICLES' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      // localStorage.setItem('formStep', 'pictures')
                      console.log('form values', values);
                      localStorage.setItem('vehiclesAssetDetails', JSON.stringify(values));
                      // localStorage.setItem('formStep', 'pictures')
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: vehicleAssetData.title,
                      product: vehicleAssetData.product,
                      purpose: vehicleAssetData.purpose,
                      vehicle_sub_category: vehicleAssetData.vehicle_sub_category,
                      // vehicle_sub_category: vehicleAssetData.vehicle_sub_category ? options.find((opt) => opt.value === vehicleAssetData.vehicle_sub_category) : null,
                      brand: vehicleAssetData.brand,
                      specify_others: vehicleAssetData.specify_others,
                      tracker: vehicleAssetData.tracker,
                      model: vehicleAssetData.model,
                      mileage: vehicleAssetData.mileage,
                      color: vehicleAssetData.color,
                      parking_status: vehicleAssetData.parking_status,
                      year_of_manufacture: vehicleAssetData.year_of_manufacture,
                      description: vehicleAssetData.description,
                    }}
                    schema={vehiclesAssetDetailsSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Vehicle</div>
                        <div className="">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="space-y-4">
                              <InputField
                                label="Number Plate"
                                error={formState.errors['title']}
                                value={vehicleAssetData.title}
                                registration={register('title')}
                                caption="Please provide the number plate of the vehicle to help you identify or search for your asset easily."
                                onChange={(e) => {
                                  formState.errors['title'] = '';
                                  setValue('title', e.target.value);
                                  // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                  const dt = { ...vehicleAssetData, title: e.target.value };
                                  setvehicleAssetData(dt);
                                  storage.setAnyFormValues('vehicleAssetData', dt);
                                }}
                              />

                              <ReactSelectField2
                                label="Select category of your vehicle"
                                type="text"
                                editorContent={watch('vehicle_sub_category')}
                                name="vehicle_sub_category"
                                defaultValue={vehicleAssetData.vehicle_sub_category}
                                // defaultValue={watch('vehicle_sub_category')}
                                error={formState.errors['vehicle_sub_category']}
                                registration={register('vehicle_sub_category')}
                                options={[
                                  { label: 'Private Vehicles', value: 'personal_vehicles' },
                                  { label: 'Bikes', value: 'bikes' },
                                  { label: 'Heavy Vehicles', value: 'heavy_vehicles' },
                                  { label: 'Commercial Vehicles', value: 'commercial_vehicles' },
                                ]}
                                onChange={(e) => {
                                  formState.errors['vehicle_sub_category'] = '';
                                  setValue('vehicle_sub_category', e.value);
                                  setvehicleAssetData({
                                    ...vehicleAssetData,
                                    vehicle_sub_category: e.value,
                                  });
                                  storage.setAnyFormValues('vehicleAssetData', {
                                    ...vehicleAssetData,
                                    vehicle_sub_category: e.value,
                                  });
                                  // setVehicleSubCategory(e.value)
                                }}
                              />

                              <InputField
                                label="Color"
                                error={formState.errors['color']}
                                value={vehicleAssetData.color}
                                registration={register('color')}
                                caption="Please provide the color of your vehicle asset."
                                onChange={(e) => {
                                  formState.errors['color'] = '';
                                  setValue('color', e.target.value);
                                  // console.log('color data', { ...vehicleAssetData, color: e.target.value })
                                  const dt = { ...vehicleAssetData, color: e.target.value };
                                  setvehicleAssetData(dt);
                                  storage.setAnyFormValues('vehicleAssetData', dt);
                                }}
                              />
                              <ReactSelectField2
                                label="Please select a loan product"
                                caption="Select a loan product that best suits your needs and preferences."
                                type="text"
                                editorContent={watch('product')}
                                name="product"
                                defaultValue={vehicleAssetData.selectedProduct}
                                error={formState.errors['product']}
                                registration={register('product')}
                                options={productsQuery.data?.map((p) => ({
                                  label: p.product_title,
                                  value: p._id,
                                }))}
                                onChange={async (e) => {
                                  const selectedProduct = await productsQuery.data
                                    ?.map((p) => ({
                                      label: p.product_title,
                                      value: p._id,
                                    }))
                                    ?.find((p) => p.value === e.value);
                                  setValue('product', e.value);
                                  formState.errors['product'] = '';
                                  setvehicleAssetData({
                                    ...vehicleAssetData,
                                    product: e.value,
                                    selectedProduct: selectedProduct,
                                  });
                                  storage.setAnyFormValues('vehicleAssetData', {
                                    product: e.value,
                                    selectedProduct: selectedProduct,
                                  });
                                }}
                              />
                              {vehicleAssetData.vehicle_sub_category && (
                                <ReactSelectField2
                                  label={
                                    vehicleSubCategory !== 'bikes'
                                      ? 'Select a car brand'
                                      : 'Select a bike brand'
                                  }
                                  type="text"
                                  editorContent={watch('brand')}
                                  name="brand"
                                  defaultValue={vehicleAssetData.brand}
                                  error={formState.errors['brand']}
                                  registration={register('brand')}
                                  options={
                                    vehicleSubCategory !== 'bikes'
                                      ? [
                                          { label: 'Toyota', value: 'TOYOTA' },
                                          { label: 'Nissan', value: 'NISSAN' },
                                          { label: 'Subaru', value: 'SUBARU' },
                                          { label: 'Mitsubishi', value: 'MITSUBISHI' },
                                          { label: 'Honda', value: 'HONDA' },
                                          { label: 'Mercedes Benz', value: 'MERCEDES_BENZ' },
                                          { label: 'BMW', value: 'BMW' },
                                          { label: 'Audi', value: 'AUDI' },
                                          { label: 'Volkswagen', value: 'VOLKSWAGEN' },
                                          { label: 'Peugeot', value: 'PEUGEOT' },
                                          { label: 'Suzuki', value: 'SUZUKI' },
                                          { label: 'Hyundai', value: 'HYUNDAI' },
                                          { label: 'Kia', value: 'KIA' },
                                          { label: 'Ford', value: 'FORD' },
                                          { label: 'Chevrolet', value: 'CHEVROLET' },
                                          { label: 'Jeep', value: 'JEEP' },
                                          { label: 'Range Rover', value: 'RANGE_ROVER' },
                                          { label: 'Land Rover', value: 'LAND_ROVER' },
                                          { label: 'Lexus', value: 'LEXUS' },
                                          { label: 'Mazda', value: 'MAZDA' },
                                          { label: 'Volvo', value: 'VOLVO' },
                                          { label: 'Porsche', value: 'PORSCHE' },
                                          { label: 'Jaguar', value: 'JAGUAR' },
                                          { label: 'Mini', value: 'MINI' },
                                          { label: 'Fiat', value: 'FIAT' },
                                          { label: 'Skoda', value: 'SKODA' },
                                          { label: 'Other', value: 'OTHER' },
                                        ]
                                      : [
                                          { label: 'Bajaj', value: 'BAJAJ' },
                                          { label: 'TVS', value: 'TVS' },
                                          { label: 'Honda', value: 'HONDA' },
                                          { label: 'Yamaha', value: 'YAMAHA' },
                                          { label: 'Suzuki', value: 'SUZUKI' },
                                          { label: 'Other', value: 'OTHER' },
                                        ]
                                  }
                                  onChange={(e) => {
                                    formState.errors['brand'] = '';
                                    setValue('brand', e.value);
                                    setvehicleAssetData({ ...vehicleAssetData, brand: e.value });
                                    storage.setAnyFormValues('vehicleAssetData', {
                                      ...vehicleAssetData,
                                      brand: e.value,
                                    });
                                  }}
                                />
                              )}
                              {vehicleAssetData.vehicle_sub_category &&
                                vehicleAssetData.brand === 'OTHER' && (
                                  <InputField
                                    label="Specify Other Brand"
                                    error={formState.errors['specify_others']}
                                    value={vehicleAssetData.specify_others}
                                    registration={register('specify_others')}
                                    caption="Specify a brand in case you can't find yours in the list"
                                    onChange={(e) => {
                                      formState.errors['specify_others'] = '';
                                      setValue('specify_others', e.target.value);
                                      setvehicleAssetData({
                                        ...vehicleAssetData,
                                        specify_others: e.target.value,
                                      });
                                      storage.setAnyFormValues('vehicleAssetData', {
                                        ...vehicleAssetData,
                                        specify_others: e.target.value,
                                      });
                                    }}
                                  />
                                )}
                              {vehicleAssetData.vehicle_sub_category && (
                                <ReactSelectField2
                                  label={
                                    vehicleAssetData.vehicle_sub_category !== 'bikes'
                                      ? 'Select car model'
                                      : 'Select bike model'
                                  }
                                  type="text"
                                  editorContent={watch('model')}
                                  name="model"
                                  defaultValue={vehicleAssetData.model}
                                  error={formState.errors['model']}
                                  registration={register('model')}
                                  options={
                                    vehicleAssetData.vehicle_sub_category !== 'bikes'
                                      ? [
                                          { label: 'Saloon', value: 'SALOON' },
                                          { label: 'SUV', value: 'SUV' },
                                          { label: 'Station Wagon', value: 'STATION_WAGON' },
                                          { label: 'Pickup', value: 'PICKUP' },
                                          { label: 'Van', value: 'VAN' },
                                          { label: 'Truck', value: 'TRUCK' },
                                          { label: 'Bus', value: 'BUS' },
                                          { label: 'Other', value: 'OTHER' },
                                        ]
                                      : [
                                          { label: 'Boda Boda', value: 'BODA_BODA' },
                                          { label: 'Sport Bike', value: 'SPORT_BIKE' },
                                          { label: 'Cruiser', value: 'CRUISER' },
                                          { label: 'Dirt Bike', value: 'DIRT_BIKE' },
                                          { label: 'Other', value: 'OTHER' },
                                        ]
                                  }
                                  onChange={(e) => {
                                    formState.errors['model'] = '';
                                    setValue('model', e.value);
                                    setvehicleAssetData({ ...vehicleAssetData, model: e.value });
                                  }}
                                />
                              )}
                              {vehicleAssetData.vehicle_sub_category && (
                                <InputField
                                  type="number"
                                  label="Year of Manufacture"
                                  error={formState.errors['year_of_manufacture']}
                                  registration={register('year_of_manufacture')}
                                  value={vehicleAssetData.year_of_manufacture}
                                  onChange={(e) => {
                                    formState.errors['year_of_manufacture'] = '';
                                    setValue('year_of_manufacture', parseInt(e.target.value));
                                    setvehicleAssetData({
                                      ...vehicleAssetData,
                                      year_of_manufacture: e.target.value,
                                    });
                                  }}
                                />
                              )}
                              {vehicleAssetData.vehicle_sub_category && (
                                <ReactSelectField2
                                  label="Select Mileage"
                                  type="text"
                                  editorContent={watch('mileage')}
                                  name="mileage"
                                  registration={register('mileage')}
                                  defaultValue={vehicleAssetData.mileage}
                                  error={formState.errors['mileage']}
                                  options={[
                                    { label: '0 - 90000', value: '0_90000' },
                                    {
                                      label: '91000 - 180000',
                                      value: '91000_180000',
                                    },
                                    {
                                      label: '181000 - 300000',
                                      value: '181000_300000',
                                    },
                                  ]}
                                  onChange={(e) => {
                                    formState.errors['mileage'] = '';
                                    setValue('mileage', e.value);
                                    setvehicleAssetData({ ...vehicleAssetData, mileage: e.value });
                                  }}
                                />
                              )}
                            </div>

                            {vehicleAssetData.vehicle_sub_category && (
                              <div className="">
                                <ReactSelectFieldWithInfo
                                  label="Tracker"
                                  caption="A tracker is installed for the safety of your vehicle. Would you like to hire or buy one?"
                                  type="text"
                                  editorContent={watch('tracker')}
                                  name="tracker"
                                  defaultValue={vehicleAssetData.tracker}
                                  error={formState.errors['tracker']}
                                  registration={register('tracker')}
                                  infoTitle="Vehicle Tracker"
                                  infoDescription="Tracker is installed for the safety of your vehicle. It helps in tracking the vehicle in case of theft."
                                  options={[
                                    { label: 'Hire', value: 'HIRE' },
                                    { label: 'Buy', value: 'BUY' },
                                  ]}
                                  onChange={(e) => {
                                    formState.errors['tracker'] = '';
                                    setValue('tracker', e.value);
                                    setvehicleAssetData({ ...vehicleAssetData, tracker: e.value });
                                    storage.setAnyFormValues('vehicleAssetData', {
                                      ...vehicleAssetData,
                                      tracker: e.value,
                                    });
                                  }}
                                />
                                <br />
                                <RegularTextAreaField
                                  label="Asset Description"
                                  error={formState.errors['description']}
                                  registration={register('description')}
                                  caption="Any extra information you’d like us to know about your vehicle e.g Chasis No. This will
                                guide us on serving you better."
                                  value={vehicleAssetData.description}
                                  onChange={(e) => {
                                    formState.errors['description'] = '';
                                    setValue('description', e.target.value);
                                    setvehicleAssetData({
                                      ...vehicleAssetData,
                                      description: e.target.value,
                                    });
                                  }}
                                />
                                <br />
                                <ReactSelectField2
                                  label="Parking Status"
                                  type="text"
                                  editorContent={watch('parking_status')}
                                  name="parking_status"
                                  defaultValue={vehicleAssetData.parking_status}
                                  error={formState.errors['parking_status']}
                                  registration={register('parking_status')}
                                  options={[
                                    { label: 'Not Parked', value: 'NOT_PARKED' },
                                    { label: 'Parked', value: 'PARKED' },
                                  ]}
                                  onChange={(e) => {
                                    formState.errors['parking_status'] = '';
                                    setValue('parking_status', e.value);
                                    setvehicleAssetData({
                                      ...vehicleAssetData,
                                      parking_status: e.value,
                                    });
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      // localStorage.setItem('vehicleImageDetail', JSON.stringify(assetImages))
                      // localStorage.setItem('formStep', 'valuation')
                      setPictures(false);
                      setValuation(true);
                      // console.log('values', values)
                    }}
                    // schema={vehiclesPicturesSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded vehicle images are clear and follow the
                          guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {[...Array(12).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                name={`imageinput${index}`}
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {index === 0 && 'Front view (show number plate)'}
                                  {index === 1 && 'Side view (both sides)'}
                                  {index === 2 && 'Back view'}
                                  {index === 3 &&
                                    'Close-ups of any existing damage or unique features'}
                                  {index === 4 && "Driver's seat area"}
                                  {index === 5 && 'Passenger seating area'}
                                  {index === 6 && 'Dashboard and control panel'}
                                  {index === 7 && 'Close-ups of odometer, indicating mileage'}
                                  {index === 8 && 'Underside of vehicle'}
                                  {index === 9 && 'Close-up of Wheels and Tyres'}
                                  {index === 10 && 'Engine'}
                                  {index === 11 && 'Logbook'}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      // let aw = localStorage.getItem('vehicleAssetData')
                      // const as = localStorage.getItem('assetCategory')
                      if (!category) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (!vehicleAssetData) {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      // console.log('data', values, vehicleAssetData)
                      const bodyFormData = new FormData();
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        setLandErr('Step 3 info missing');
                        return;
                      }
                      bodyFormData.append('title', vehicleAssetData.title);
                      bodyFormData.append('category', category);
                      bodyFormData.append(
                        'vehicle_sub_category',
                        vehicleAssetData.vehicle_sub_category
                      );
                      bodyFormData.append('brand', vehicleAssetData.brand);
                      bodyFormData.append('product', vehicleAssetData.product);
                      bodyFormData.append('mileage', vehicleAssetData.mileage);
                      bodyFormData.append('model', vehicleAssetData.model);
                      bodyFormData.append(
                        'year_of_manufacture',
                        vehicleAssetData.year_of_manufacture
                      );
                      bodyFormData.append('color', vehicleAssetData.color);
                      bodyFormData.append('description', vehicleAssetData.description);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('current_worth', values.current_worth);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      bodyFormData.append('specify_others', vehicleAssetData.specify_others);
                      bodyFormData.append('tracker', vehicleAssetData.tracker);
                      // bodyFormData.append('color', vehicleAssetData.color);
                      bodyFormData.append('parking_status', vehicleAssetData.parking_status);

                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('vehicleAssetData');
                          localStorage.removeItem('assetCategory');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={vehicleValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy the vehicle? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it? (DD/MM/YYYY)"
                            caption="Please provide a date in the format (Date / Month / Year)"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'SMART_PHONES' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      const dt = storage.getOtherValues('smartPhoneAssetData');
                      console.log('stored dt', dt);
                      localStorage.setItem('formStep', 'pictures');
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: smartPhoneAssetData.title,
                      product: smartPhoneAssetData.product,
                      brand: smartPhoneAssetData.brand,
                      specify_others: smartPhoneAssetData.specify_others,
                      ram_size: smartPhoneAssetData.ram_size,
                      model: smartPhoneAssetData.model,
                      storage_space: smartPhoneAssetData.storage_space,
                      description: smartPhoneAssetData.description,
                    }}
                    schema={smartPhoneSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Smart Phone</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(e) => {
                                formState.errors['title'] = '';
                                setValue('title', e.target.value);
                                // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                const dt = { ...smartPhoneAssetData, title: e.target.value };
                                setSmartPhoneAssetData(dt);
                                storage.setAnyFormValues('smartPhoneAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={smartPhoneAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data
                                  ?.map((p) => ({
                                    label: p.product_title,
                                    value: p._id,
                                  }))
                                  ?.find((p) => p.value === e.value);
                                setValue('product', e.value);
                                formState.errors['product'] = '';
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                                storage.setAnyFormValues('smartPhoneAssetData', {
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Mobile Phone Brand"
                              type="text"
                              editorContent={watch('brand')}
                              name="brand"
                              defaultValue={smartPhoneAssetData.selectedBrand}
                              error={formState.errors['brand']}
                              registration={register('brand')}
                              options={phoneBrands}
                              onChange={async (e) => {
                                const selectedProduct = await phoneBrands.find(
                                  (p) => p.value === e.value
                                );
                                setValue('brand', e.value);
                                formState.errors['brand'] = '';
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                                storage.setAnyFormValues('smartPhoneAssetData', {
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                              }}
                            />
                            {smartPhoneAssetData.brand === 'OTHER' && (
                              <InputField
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                                onChange={(e) => {
                                  formState.errors['specify_others'] = '';
                                  setValue('specify_others', e.target.value);
                                  // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                                  const dt = {
                                    ...smartPhoneAssetData,
                                    specify_others: e.target.value,
                                  };
                                  setSmartPhoneAssetData(dt);
                                  storage.setAnyFormValues('smartPhoneAssetData', dt);
                                }}
                              />
                            )}
                            <ReactSelectField2
                              label="RAM Size"
                              type="text"
                              editorContent={watch('ram_size')}
                              name="ram_size"
                              defaultValue={smartPhoneAssetData.selectedram_size}
                              error={formState.errors['ram_size']}
                              registration={register('ram_size')}
                              options={defaults.RAMSize}
                              onChange={async (e) => {
                                const selectedProduct = defaults.RAMSize.find(
                                  (p) => p.value === e.value
                                );
                                setValue('ram_size', e.value);
                                formState.errors['ram_size'] = '';
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  ram_size: e.value,
                                  selectedram_size: selectedProduct,
                                });
                                storage.setAnyFormValues('smartPhoneAssetData', {
                                  ram_size: e.value,
                                  selectedram_size: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Phone Model"
                              type="text"
                              editorContent={watch('model')}
                              name="model"
                              defaultValue={smartPhoneAssetData.selectedModel}
                              error={formState.errors['model']}
                              registration={register('model')}
                              options={phoneModels}
                              onChange={async (e) => {
                                const selectedProduct = await phoneModels.find(
                                  (p) => p.value === e.value
                                );
                                setValue('model', e.value);
                                formState.errors['model'] = '';
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  model: e.value,
                                  selectedModel: selectedProduct,
                                });
                                storage.setAnyFormValues('smartPhoneAssetData', {
                                  model: e.value,
                                  selectedModel: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Storage Space"
                              type="text"
                              editorContent={watch('storage_space')}
                              name="storage_space"
                              defaultValue={smartPhoneAssetData.selectedstorage_space}
                              error={formState.errors['storage_space']}
                              registration={register('storage_space')}
                              options={defaults.storageSpace}
                              onChange={async (e) => {
                                const selectedProduct = defaults.storageSpace.find(
                                  (p) => p.value === e.value
                                );
                                setValue('storage_space', e.value);
                                formState.errors['storage_space'] = '';
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  storage_space: e.value,
                                  selectedstorage_space: selectedProduct,
                                });
                                storage.setAnyFormValues('smartPhoneAssetData', {
                                  storage_space: e.value,
                                  selectedstorage_space: selectedProduct,
                                });
                              }}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your smart phone. This will
                                guide us on serving you better."
                              value={smartPhoneAssetData.description}
                              onChange={(e) => {
                                formState.errors['description'] = '';
                                setValue('description', e.target.value);
                                setSmartPhoneAssetData({
                                  ...smartPhoneAssetData,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    schema={smartPhonePicturesSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your mobile phone are clear and
                          follow the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}

                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      // let aw = localStorage.getItem('vehicleAssetData')
                      // const as = localStorage.getItem('assetCategory')
                      if (!category) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (!smartPhoneAssetData) {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      const bodyFormData = new FormData();
                      // console.log('data', values, vehicleAssetData)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        setLandErr('Step 3 info missing');
                        return;
                      }

                      bodyFormData.append('title', smartPhoneAssetData.title);
                      bodyFormData.append('category', category);
                      bodyFormData.append('brand', smartPhoneAssetData.brand);
                      bodyFormData.append('specify_others', smartPhoneAssetData.specify_others);
                      bodyFormData.append('ram_size', smartPhoneAssetData.ram_size);
                      bodyFormData.append('storage_space', smartPhoneAssetData.storage_space);
                      bodyFormData.append('model', smartPhoneAssetData.model);
                      bodyFormData.append('product', smartPhoneAssetData.product);
                      bodyFormData.append('description', smartPhoneAssetData.description);

                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('current_worth', values.current_worth);
                      bodyFormData.append('purchase_date', values.purchase_date);

                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('smartPhoneAssetData');
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('pictureAssetData');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          setAssetImages(Array(12).fill(''));
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={smartPhoneValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy the Smart Phone? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it? (DD/MM/YYYY)"
                            caption="Please provide a date in the format (Date / Month / Year)"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'TELEVISION' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      localStorage.setItem('formStep', 'pictures');
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: televisionAssetData.title,
                      product: televisionAssetData.product,
                      brand: televisionAssetData.brand,
                      specify_others: televisionAssetData.specify_others,
                      dimension: televisionAssetData.dimension,
                      smart_tv: televisionAssetData.smart_tv,
                      description: televisionAssetData.description,
                    }}
                    schema={televisionSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Television</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(e) => {
                                formState.errors['title'] = '';
                                setValue('title', e.target.value);
                                // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                const dt = { ...televisionAssetData, title: e.target.value };
                                setTelevisionAssetData(dt);
                                storage.setAnyFormValues('televisionAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={televisionAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data
                                  ?.map((p) => ({
                                    label: p.product_title,
                                    value: p._id,
                                  }))
                                  ?.find((p) => p.value === e.value);
                                setValue('product', e.value);
                                formState.errors['product'] = '';
                                setTelevisionAssetData({
                                  ...televisionAssetData,
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                                storage.setAnyFormValues('televisionAssetData', {
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Television Brand"
                              type="text"
                              editorContent={watch('brand')}
                              name="brand"
                              defaultValue={televisionAssetData.selectedBrand}
                              error={formState.errors['brand']}
                              registration={register('brand')}
                              options={defaults.tvBrand}
                              onChange={async (e) => {
                                const selectedProduct = defaults.tvBrand.find(
                                  (p) => p.value === e.value
                                );
                                setValue('brand', e.value);
                                formState.errors['brand'] = '';
                                setTelevisionAssetData({
                                  ...televisionAssetData,
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                                storage.setAnyFormValues('televisionAssetData', {
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                              }}
                            />
                            {televisionAssetData.brand === 'OTHER' && (
                              <InputField
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                                onChange={(e) => {
                                  formState.errors['specify_others'] = '';
                                  setValue('specify_others', e.target.value);
                                  // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                                  const dt = {
                                    ...televisionAssetData,
                                    specify_others: e.target.value,
                                  };
                                  setTelevisionAssetData(dt);
                                  storage.setAnyFormValues('televisionAssetData', dt);
                                }}
                              />
                            )}
                            <ReactSelectField2
                              label="Dimensions"
                              type="text"
                              editorContent={watch('dimension')}
                              name="dimension"
                              defaultValue={televisionAssetData.selecteddimension}
                              error={formState.errors['dimension']}
                              registration={register('dimension')}
                              options={defaults.tvDimensions}
                              onChange={async (e) => {
                                const selectedProduct = defaults.tvDimensions.find(
                                  (p) => p.value === e.value
                                );
                                setValue('dimension', e.value);
                                formState.errors['dimension'] = '';
                                setTelevisionAssetData({
                                  ...televisionAssetData,
                                  dimension: e.value,
                                  selecteddimension: selectedProduct,
                                });
                                storage.setAnyFormValues('televisionAssetData', {
                                  dimension: e.value,
                                  selecteddimension: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Smart TV?"
                              type="text"
                              editorContent={watch('smart_tv')}
                              name="smart_tv"
                              defaultValue={televisionAssetData.selectedsmart_tv}
                              error={formState.errors['smart_tv']}
                              registration={register('smart_tv')}
                              options={defaults.isSmartTv}
                              onChange={async (e) => {
                                const selectedProduct = defaults.isSmartTv.find(
                                  (p) => p.value === e.value
                                );
                                setValue('smart_tv', e.value);
                                formState.errors['smart_tv'] = '';
                                setTelevisionAssetData({
                                  ...televisionAssetData,
                                  smart_tv: e.value,
                                  selectedsmart_tv: selectedProduct,
                                });
                                storage.setAnyFormValues('televisionAssetData', {
                                  smart_tv: e.value,
                                  selectedsmart_tv: selectedProduct,
                                });
                              }}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your television. This will
                                guide us on serving you better."
                              value={televisionAssetData.description}
                              onChange={(e) => {
                                formState.errors['description'] = '';
                                setValue('description', e.target.value);
                                setTelevisionAssetData({
                                  ...televisionAssetData,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    schema={televisionPicturesSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your mobile phone are clear and
                          follow the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}

                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      // let aw = localStorage.getItem('vehicleAssetData')
                      // const as = localStorage.getItem('assetCategory')
                      if (!category) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (!televisionAssetData) {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      const bodyFormData = new FormData();
                      // console.log('data', values, vehicleAssetData)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        setLandErr('Step 3 info missing');
                        return;
                      }
                      bodyFormData.append('title', televisionAssetData.title);
                      bodyFormData.append('category', category);
                      bodyFormData.append('brand', televisionAssetData.brand);
                      bodyFormData.append('specify_others', televisionAssetData.specify_others);
                      bodyFormData.append('dimension', televisionAssetData.dimension);
                      bodyFormData.append('smart_tv', televisionAssetData.smart_tv);
                      bodyFormData.append('product', televisionAssetData.product);
                      bodyFormData.append('description', televisionAssetData.description);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      bodyFormData.append('current_worth', values.current_worth);
                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('televisionAssetData');
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('pictureAssetData');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          setAssetImages(Array(12).fill(''));
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={televisionValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy the Television? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it? (DD/MM/YYYY)"
                            caption="Please provide a date in the format (Date / Month / Year)"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'COMPUTERS' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      localStorage.setItem('formStep', 'pictures');
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: computerAssetData.title,
                      product: computerAssetData.product,
                      brand: computerAssetData.brand,
                      specify_others: computerAssetData.specify_others,
                      ram_size: computerAssetData.ram_size,

                      processor_speed: computerAssetData.processor_speed,
                      hard_disk_space: computerAssetData.hard_disk_space,
                      description: computerAssetData.description,
                    }}
                    schema={computersSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for Computer</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(e) => {
                                formState.errors['title'] = '';
                                setValue('title', e.target.value);
                                // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                const dt = { ...computerAssetData, title: e.target.value };
                                setComputerAssetData(dt);
                                storage.setAnyFormValues('computerAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={computerAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data
                                  ?.map((p) => ({
                                    label: p.product_title,
                                    value: p._id,
                                  }))
                                  ?.find((p) => p.value === e.value);
                                setValue('product', e.value);
                                formState.errors['product'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Computer Brand"
                              type="text"
                              editorContent={watch('brand')}
                              name="brand"
                              defaultValue={computerAssetData.selectedBrand}
                              error={formState.errors['brand']}
                              registration={register('brand')}
                              options={defaults.computerBrand}
                              onChange={async (e) => {
                                const selectedProduct = defaults.computerBrand.find(
                                  (p) => p.value === e.value
                                );
                                setValue('brand', e.value);
                                formState.errors['brand'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  brand: e.value,
                                  selectedBrand: selectedProduct,
                                });
                              }}
                            />
                            {computerAssetData.brand === 'OTHER' && (
                              <InputField
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                                onChange={(e) => {
                                  formState.errors['specify_others'] = '';
                                  setValue('specify_others', e.target.value);
                                  // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                                  const dt = {
                                    ...computerAssetData,
                                    specify_others: e.target.value,
                                  };
                                  setComputerAssetData(dt);
                                  storage.setAnyFormValues('computerAssetData', dt);
                                }}
                              />
                            )}
                            <ReactSelectField2
                              label="RAM Size"
                              type="text"
                              editorContent={watch('ram_size')}
                              name="ram_size"
                              defaultValue={computerAssetData.selectedram_size}
                              error={formState.errors['ram_size']}
                              registration={register('ram_size')}
                              options={defaults.RAMSize}
                              onChange={async (e) => {
                                const selectedProduct = defaults.RAMSize.find(
                                  (p) => p.value === e.value
                                );
                                setValue('ram_size', e.value);
                                formState.errors['ram_size'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  ram_size: e.value,
                                  selectedram_size: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  ram_size: e.value,
                                  selectedram_size: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Processor Speed"
                              type="text"
                              editorContent={watch('processor_speed')}
                              name="processor_speed"
                              defaultValue={computerAssetData.selectedprocessor_speed}
                              error={formState.errors['processor_speed']}
                              registration={register('processor_speed')}
                              options={defaults.computerProcessor}
                              onChange={async (e) => {
                                const selectedProduct = defaults.computerProcessor.find(
                                  (p) => p.value === e.value
                                );
                                setValue('processor_speed', e.value);
                                formState.errors['processor_speed'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  processor_speed: e.value,
                                  selectedprocessor_speed: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  processor_speed: e.value,
                                  selectedprocessor_speed: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Hard Disk Space"
                              type="text"
                              editorContent={watch('hard_disk_space')}
                              name="hard_disk_space"
                              defaultValue={computerAssetData.selectedhard_disk_space}
                              error={formState.errors['hard_disk_space']}
                              registration={register('hard_disk_space')}
                              options={defaults.computerHarddisk}
                              onChange={async (e) => {
                                const selectedProduct = defaults.computerHarddisk.find(
                                  (p) => p.value === e.value
                                );
                                setValue('hard_disk_space', e.value);
                                formState.errors['hard_disk_space'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  hard_disk_space: e.value,
                                  selectedhard_disk_space: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  hard_disk_space: e.value,
                                  selectedhard_disk_space: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Hard Disk Type"
                              type="text"
                              editorContent={watch('hard_disk_type')}
                              name="hard_disk_type"
                              defaultValue={computerAssetData.selectedhard_disk_type}
                              error={formState.errors['hard_disk_type']}
                              registration={register('hard_disk_type')}
                              options={defaults.computerHardDiskType}
                              onChange={async (e) => {
                                const selectedProduct = defaults.computerHardType.find(
                                  (p) => p.value === e.value
                                );
                                setValue('hard_disk_type', e.value);
                                formState.errors['hard_disk_type'] = '';
                                setComputerAssetData({
                                  ...computerAssetData,
                                  hard_disk_type: e.value,
                                  selectedhard_disk_type: selectedProduct,
                                });
                                storage.setAnyFormValues('computerAssetData', {
                                  hard_disk_type: e.value,
                                  selectedhard_disk_type: selectedProduct,
                                });
                              }}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your computer. This will
                                guide us on serving you better."
                              value={computerAssetData.description}
                              onChange={(e) => {
                                formState.errors['description'] = '';
                                setValue('description', e.target.value);
                                setComputerAssetData({
                                  ...computerAssetData,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    schema={computerPicturesSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your computer are clear and follow
                          the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      // let aw = localStorage.getItem('vehicleAssetData')
                      // const as = localStorage.getItem('assetCategory')
                      if (!category) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (!computerAssetData) {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      const bodyFormData = new FormData();
                      // console.log('data', values, vehicleAssetData)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        setLandErr('Step 3 info missing');
                        return;
                      }
                      bodyFormData.append('title', computerAssetData.title);
                      bodyFormData.append('category', category);
                      bodyFormData.append('brand', computerAssetData.brand);
                      bodyFormData.append('specify_others', computerAssetData.specify_others);
                      bodyFormData.append('ram_size', computerAssetData.ram_size);
                      bodyFormData.append('hard_disk_space', computerAssetData.hard_disk_space);
                      bodyFormData.append('processor_speed', computerAssetData.processor_speed);
                      bodyFormData.append('product', computerAssetData.product);
                      bodyFormData.append('description', computerAssetData.description);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      bodyFormData.append('current_worth', values.current_worth);
                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('computerAssetData');
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('pictureAssetData');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          setAssetImages(Array(12).fill(''));
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={computerValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy the Computer? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it? (DD/MM/YYYY)"
                            caption="Please provide a date in the format (Date / Month / Year)"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
            {category === 'OTHER_ELECTRONICS' && (
              <>
                {assetDetails && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      localStorage.setItem('formStep', 'pictures');
                      setAssetDetails(false);
                      setPictures(true);
                    }}
                    defaultValues={{
                      title: otherElectronicsAssetData.title,
                      product: otherElectronicsAssetData.product,
                      nature_of_electronic: otherElectronicsAssetData.nature_of_electronic,
                      specify_others: otherElectronicsAssetData.specify_others,
                      model_type: otherElectronicsAssetData.model_type,
                      description: otherElectronicsAssetData.description,
                    }}
                    schema={otherElectronicsSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Asset Details for other electronics</div>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="space-y-5">
                            <InputField
                              label="Title of Asset"
                              error={formState.errors['title']}
                              registration={register('title')}
                              caption="Enter a concise title for your asset. This will help us identify it easily and efficiently."
                              onChange={(e) => {
                                formState.errors['title'] = '';
                                setValue('title', e.target.value);
                                // console.log('title data', { ...vehicleAssetData, title: e.target.value })
                                const dt = { ...otherElectronicsAssetData, title: e.target.value };
                                setOtherElectronicsAssetData(dt);
                                storage.setAnyFormValues('otherElectronicsAssetData', dt);
                              }}
                            />
                            <ReactSelectField2
                              label="Please select Product"
                              type="text"
                              editorContent={watch('product')}
                              name="product"
                              defaultValue={otherElectronicsAssetData.selectedProduct}
                              error={formState.errors['product']}
                              registration={register('product')}
                              options={productsQuery.data?.map((p) => ({
                                label: p.product_title,
                                value: p._id,
                              }))}
                              onChange={async (e) => {
                                const selectedProduct = await productsQuery.data
                                  ?.map((p) => ({
                                    label: p.product_title,
                                    value: p._id,
                                  }))
                                  ?.find((p) => p.value === e.value);
                                setValue('product', e.value);
                                formState.errors['product'] = '';
                                setComputerAssetData({
                                  ...otherElectronicsAssetData,
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                                storage.setAnyFormValues('otherElectronicsAssetData', {
                                  product: e.value,
                                  selectedProduct: selectedProduct,
                                });
                              }}
                            />
                            <ReactSelectField2
                              label="Select Electronic"
                              type="text"
                              editorContent={watch('nature_of_electronic')}
                              name="nature_of_electronic"
                              defaultValue={otherElectronicsAssetData.selectednature_of_electronic}
                              error={formState.errors['nature_of_electronic']}
                              registration={register('nature_of_electronic')}
                              options={defaults.electronics}
                              onChange={async (e) => {
                                const selectedProduct = defaults.electronics.find(
                                  (p) => p.value === e.value
                                );
                                setValue('nature_of_electronic', e.value);
                                formState.errors['nature_of_electronic'] = '';
                                setOtherElectronicsAssetData({
                                  ...otherElectronicsAssetData,
                                  nature_of_electronic: e.value,
                                  selectednature_of_electronic: selectedProduct,
                                });
                                storage.setAnyFormValues('otherElectronicsAssetData', {
                                  nature_of_electronic: e.value,
                                  selectednature_of_electronic: selectedProduct,
                                });
                              }}
                            />
                            {otherElectronicsAssetData.nature_of_electronic === 'OTHER' && (
                              <InputField
                                label="Specify Other Brand"
                                error={formState.errors['specify_others']}
                                registration={register('specify_others')}
                                caption="Specify a brand in case you can't find yours in the list"
                                onChange={(e) => {
                                  formState.errors['specify_others'] = '';
                                  setValue('specify_others', e.target.value);
                                  // console.log('specify_others data', { ...vehicleAssetData, specify_others: e.target.value })
                                  const dt = {
                                    ...otherElectronicsAssetData,
                                    specify_others: e.target.value,
                                  };
                                  setOtherElectronicsAssetData(dt);
                                  storage.setAnyFormValues('otherElectronicsAssetData', dt);
                                }}
                              />
                            )}
                            <InputField
                              label="Model Type"
                              error={formState.errors['model_type']}
                              registration={register('model_type')}
                              caption="Specify a brand in case you can't find yours in the list"
                              onChange={(e) => {
                                formState.errors['model_type'] = '';
                                setValue('model_type', e.target.value);
                                // console.log('model_type data', { ...vehicleAssetData, model_type: e.target.value })
                                const dt = {
                                  ...otherElectronicsAssetData,
                                  model_type: e.target.value,
                                };
                                setOtherElectronicsAssetData(dt);
                                storage.setAnyFormValues('otherElectronicsAssetData', dt);
                              }}
                            />
                          </div>
                          <div className="space-y-5">
                            <RegularTextAreaField
                              label="Asset Description"
                              error={formState.errors['description']}
                              registration={register('description')}
                              caption="Any extra information you’d like us to know about your computer. This will
                                guide us on serving you better."
                              value={otherElectronicsAssetData.description}
                              onChange={(e) => {
                                formState.errors['description'] = '';
                                setValue('description', e.target.value);
                                setOtherElectronicsAssetData({
                                  ...otherElectronicsAssetData,
                                  description: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {pictures && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setPictures(false);
                      setValuation(true);
                    }}
                    schema={otherElectronicsPicturesSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Pictures</div>
                        <div className="mb-2">
                          Please ensure the uploaded images of your computer are clear and follow
                          the guidelines on what picture angles to take.
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          {[...Array(4).keys()].map((index) => (
                            <div key={index} className="relative">
                              <input
                                type="file"
                                id={`image-input-${index}`}
                                accept="image/*"
                                className="hidden"
                                {...register(`imageinput${index}`)}
                                onChange={(event) => {
                                  formState.errors[`imageinput${index}`] = '';
                                  handleImageChange(index, event);
                                  setValue(`imageinput${index}`, 'file set');
                                }}
                              />
                              <label htmlFor={`image-input-${index}`} className="cursor-pointer">
                                <div className="w-full h-40 bg-gray-200 flex justify-center items-center rounded-lg border border-dashed border-gray-400">
                                  {assetImages[index] ? (
                                    <img
                                      src={assetImages[index]}
                                      alt={`Uploaded Image ${index + 1}`}
                                      className="max-w-full max-h-full rounded-lg"
                                    />
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-12 w-12 text-gray-500"
                                      viewBox="0 0 20 20"
                                      fill="currentColor"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        d="M13.667 3H6.333c-1.335 0-2.45.935-2.75 2.186l-.003.017-.016.08a3.2 3.2 0 0 0-.08.461V14.75c0 1.333 1.083 2.417 2.417 2.417h7.334c1.334 0 2.417-1.084 2.417-2.418V5.417C16.084 4.083 15 3 13.667 3zM10 6.25a2.75 2.75 0 1 1 0 5.5 2.75 2.75 0 0 1 0-5.5zM8.75 9A1.25 1.25 0 0 1 10 7.75h.002A1.25 1.25 0 0 1 11.25 9 1.25 1.25 0 0 1 10 10.25H9.998A1.25 1.25 0 0 1 8.75 9zM10 11.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5z"
                                        clipRule="evenodd"
                                      />
                                    </svg>
                                  )}
                                  {formState.errors[`imageinput${index}`]?.message && (
                                    <div
                                      role="alert"
                                      aria-label={formState.errors[`imageinput${index}`].message}
                                      className="text-sm font-semibold text-red-500"
                                    >
                                      {formState.errors[`imageinput${index}`].message}
                                    </div>
                                  )}
                                </div>
                                <span className="block text-center mt-2 text-gray-500">
                                  {assetImages[index]
                                    ? `Change Image ${index + 1}`
                                    : `Upload Image ${index + 1}`}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setAssetDetails(true);
                              setPictures(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button type="submit">Next</Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
                {valuation && (
                  <Form
                    id="create-post"
                    onSubmit={async (values) => {
                      setLandErr('');
                      // let aw = localStorage.getItem('vehicleAssetData')
                      // const as = localStorage.getItem('assetCategory')
                      if (!category) {
                        setLandErr('Select collateral category in step 1');
                        return;
                      }
                      if (!otherElectronicsAssetData) {
                        setLandErr('Step 2 info missing');
                        return;
                      }
                      const bodyFormData = new FormData();
                      // console.log('data', values, vehicleAssetData)
                      if (assetImages) {
                        assetImages.forEach((image, index) => {
                          if (image) {
                            bodyFormData.append(`pictures`, dataURItoBlob(image));
                          }
                        });
                      } else {
                        setLandErr('Step 3 info missing');
                        return;
                      }
                      console.log('otherElectronicsAssetData', otherElectronicsAssetData);
                      if (otherElectronicsAssetData.specify_others) {
                        bodyFormData.append(
                          'specify_others',
                          otherElectronicsAssetData.specify_others
                        );
                      }
                      bodyFormData.append('title', otherElectronicsAssetData.title);
                      bodyFormData.append('category', category);
                      bodyFormData.append(
                        'nature_of_electronic',
                        otherElectronicsAssetData.nature_of_electronic
                      );
                      bodyFormData.append('product', otherElectronicsAssetData.product);
                      bodyFormData.append('description', otherElectronicsAssetData.description);
                      bodyFormData.append('model_type', otherElectronicsAssetData.model_type);
                      bodyFormData.append('purchase_amount', values.purchase_amount);
                      bodyFormData.append('purchase_date', values.purchase_date);
                      bodyFormData.append('current_worth', values.current_worth);
                      await createAssetMutation
                        .mutateAsync({ data: bodyFormData })
                        .then((data) => {
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('formStep');
                          localStorage.removeItem('otherElectronicsAssetData');
                          localStorage.removeItem('assetCategory');
                          localStorage.removeItem('pictureAssetData');
                          setPictures(false);
                          setValuation(false);
                          setAssetDetails(true);
                          setAssetImages(Array(12).fill(''));
                          navigate('/app/assets/create/success');
                        })
                        .catch((err) => {
                          // console.log('login error', err);
                          setLandErr(`${err.message}. Try again.`);
                        });
                    }}
                    schema={otherElectronicsValuationSchema}
                  >
                    {({ register, formState, watch, setValue }) => (
                      <div className="bg-white rounded-2xl shadow p-6 mb-4 w-full">
                        <div className="mb-4">Valuation</div>
                        <div className="mb-2">
                          To enhance your prospects of securing a loan, it's imperative to furnish
                          precise and comprehensive information.
                        </div>
                        <div className="md:mx-48">
                          <div className="">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much did you buy the Electronic? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="purchase_amount"
                              // value={amount}
                              error={formState.errors['purchase_amount']}
                              registration={register('purchase_amount')}
                              onChange={(event) => {
                                //console.log('amount', event);
                                formState.errors['purchase_amount'] = '';
                                setValue('purchase_amount', event.floatValue);
                              }}
                            />
                          </div>
                          <div className="mb-2">
                            <div className="flex flex-row items-center justify-between mt-3">
                              <div className="block text-sm font-medium text-gray-700 dark:text-gray-400">
                                How much is it worth currently? (UGX)
                              </div>
                            </div>
                            <ReactNumberFormatInputField2
                              prefix="UGX "
                              name="current_worth"
                              // value={amount}
                              error={formState.errors['current_worth']}
                              registration={register('current_worth')}
                              onChange={(event) => {
                                console.log('amount', event);
                                formState.errors['current_worth'] = '';
                                setValue('current_worth', event.floatValue);
                              }}
                            />
                          </div>
                          <InputField
                            type="date"
                            label="When did you buy it? (DD/MM/YYYY)"
                            caption="Please provide a date in the format (Date / Month / Year)"
                            error={formState.errors['purchase_date']}
                            registration={register('purchase_date')}
                            onChange={(event) => {
                              //console.log('amount', event);
                              formState.errors['purchase_date'] = '';
                              setValue('purchase_date', event.target.value);
                            }}
                          />
                          {landErr && (
                            <div
                              role="alert"
                              aria-label={landErr}
                              className="text-sm font-semibold text-red-500"
                            >
                              {landErr}
                            </div>
                          )}
                        </div>
                        <div className="flex flex-row space-x-2 justify-end mt-4">
                          <Button
                            variant="outline"
                            onClick={() => {
                              setValuation(false);
                              setPictures(true);
                            }}
                          >
                            Back
                          </Button>
                          <Button isLoading={createAssetMutation.isLoading} type="submit">
                            Finish
                          </Button>
                        </div>
                      </div>
                    )}
                  </Form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
};
