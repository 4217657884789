import { Route, Routes } from 'react-router-dom';

import { Login } from './Login';
import { Register } from './Register';
import { ConfirmEmail } from './ConfirmEmail';
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from './ResetPassword';
import { CompanyRequestSubmitted } from './CompanyRequestSubmitted';
import { LinkedInRedirect } from './socials/redirect/LinkedInRedirect';
import { GoogleRedirect } from './socials/redirect/GoogleRedirect';
import { FacebookRedirect } from './socials/redirect/FacebookRedirect';
import { GithubRedirect } from './socials/redirect/GithubRedirect';
import { TwitterRedirect } from './socials/redirect/TwitterRedirect';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="oauth/facebook/redirect" element={<FacebookRedirect />} />
      <Route path="oauth/github/redirect" element={<GithubRedirect />} />
      <Route path="oauth/google/redirect" element={<GoogleRedirect />} />
      <Route path="oauth/linkedin/redirect" element={<LinkedInRedirect />} />
      <Route path="oauth/twitter/redirect" element={<TwitterRedirect />} />
      <Route path="register/confirm/:code" element={<ConfirmEmail />} />
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="company-request-submitted" element={<CompanyRequestSubmitted />} />
      <Route path="reset-password" element={<ResetPassword />} />
    </Routes>
  );
};
