import React, { useState } from 'react';
import { FieldWrapper } from './FieldWrapper';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Default location icon for Leaflet marker
const customIcon = new L.Icon({
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
  shadowSize: [41, 41],
});

const center = {
  lat: 0.39394,
  lng: 32.63648,
};

export const LocationField = ({ label, onChange, error, caption, defaultValue }) => {
  const [selectedPlace, setSelectedPlace] = useState(center);

  const MapClickHandler = () => {
    // Allow users to drop a pin on the map by clicking
    useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        const newPlace = { lat, lng };
        setSelectedPlace(newPlace);
        onChange(newPlace); // Pass new coordinates to parent component
      },
    });
    return null;
  };

  const handleMarkerDragEnd = (event) => {
    const newLat = event.target.getLatLng().lat;
    const newLng = event.target.getLatLng().lng;
    const newPlace = { lat: newLat, lng: newLng };
    setSelectedPlace(newPlace);
    // Optionally, you can reverse geocode to get the address from the coordinates here
  };

  return (
    <FieldWrapper label={label} error={error}>
      <div className="mb-2 text-sm">{caption}</div>
      <div className="relative w-full h-[400px] rounded-lg overflow-hidden border dark:bg-gray-700 dark:border-none border-gray-300 shadow-sm">
        <MapContainer
          center={selectedPlace}
          zoom={15}
          style={{ width: '100%', height: '100%' }}
          scrollWheelZoom={true}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={selectedPlace}
            draggable={true}
            eventHandlers={{
              dragend: handleMarkerDragEnd,
            }}
            icon={customIcon}
          />
          <MapClickHandler />
        </MapContainer>
      </div>
    </FieldWrapper>
  );
};
