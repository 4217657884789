import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { loginWithGithub } from 'features/auth/api/loginWithGithub';

export const GithubRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGithubInAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No Github code found in the URL.');
        navigate('/auth/login');
        return;
      }

      try {
        // Call the API with the Github code
        const response = await loginWithGithub({ code });

        const user = await handleUserResponse(response);

        console.log('Github login successful:', user);

        navigate('/app'); // Redirect to the main app
      } catch (error) {
        console.error('Error during Github login:', error);
        navigate('/auth/login'); // Redirect to login page on error
      }
    };

    handleGithubInAuth();
  }, [navigate]);

  return (
    <Layout title="Logging in with Github">
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg font-medium text-gray-600">Signing in with Github. Please wait...</p>
      </div>
    </Layout>
  );
};
