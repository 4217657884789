import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../../../components/Layout';
import { loginWithGoogle } from 'features/auth/api/loginWithGoogle';

export const GoogleRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleInAuth = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      if (!code) {
        console.error('No google code found in the URL.');
        navigate('/auth/login');
        return;
      }

      try {
        // Call the API with the Google code
        const response = await loginWithGoogle({ code });

        const user = await handleUserResponse(response);

        console.log('Google login successful:', user);

        navigate('/app'); // Redirect to the main app
      } catch (error) {
        console.error('Error during Google login:', error);
        navigate('/auth/login'); // Redirect to login page on error
      }
    };

    handleGoogleInAuth();
  }, [navigate]);

  return (
    <Layout title="Logging in with Google">
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-lg font-medium text-gray-600">Signing in with Google. Please wait...</p>
      </div>
    </Layout>
  );
};
