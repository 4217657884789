import { useEffect, useState } from "react";
import { useAuth } from "lib/auth";
import PropTypes from "prop-types";
import * as z from "zod";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { Button } from "components/Elements";
import { Form, InputField, PhoneInputField } from "components/Form";
import "react-phone-input-2/lib/style.css";
import { FaGoogle, FaLinkedin, FaGithub, FaTwitter, FaFacebook } from "react-icons/fa";
import { ChevronDown } from "react-feather";

const schema = z.object({
  phoneNumber: z
    .string({
      required_error: "Phone number is required",
      invalid_type_error: "Phone number must be a string",
    })
    .min(1, { message: "Phone number cannot be empty" }),
  password: z
    .string({
      required_error: "Password is required",
      invalid_type_error: "Password must be a string",
    })
    .min(1, { message: "Password cannot be empty" }),
});

export const LoginForm = ({ onSuccess }) => {
  const { login, isLoggingIn } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [phoneErr, setPhoneErr] = useState("");
  const [loginErr, setLoginErr] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const navigate = useNavigate();

  const socialAuthHandlers = {
    google: () => (window.location.href = "https://restapi.finablr.app/api/v2/oauth/google"),
    linkedin: () => (window.location.href = "https://restapi.finablr.app/api/v2/oauth/linkedin"),
    github: () => (window.location.href = "https://restapi.finablr.app/api/v2/oauth/github"),
    twitter: () => (window.location.href = "https://restapi.finablr.app/api/v2/oauth/twitter"),
    facebook: () => (window.location.href = "https://restapi.finablr.app/api/v2/oauth/facebook"),
  };

  return (
    <div>
      <Form
        onSubmit={async (values) => {
          setLoginErr("");
          const data = { phoneNumber, password: values.password };
          await login(data)
            .then(() => onSuccess())
            .catch((err) => setLoginErr(`${err.message}. Try again.`));
        }}
        schema={schema}
      >
        {({ register, formState, setValue }) => (
          <>
            {!showPasswordInput ? (
              <div className="flex flex-col space-y-5 mt-4">
                <div className="mb-3">
                  <div className="block text-sm font-medium text-gray-700 dark:text-gray-400 mt-3">
                    Phone Number
                  </div>
                  <PhoneInput
                    country={"ug"}
                    countryCodeEditable={false}
                    masks={{ ug: "... ... ..." }}
                    name="phoneNumber"
                    value={phoneNumber}
                    inputStyle={{
                      width: "100%",
                      height: "60px",
                      fontSize: "1rem",
                      border: "1px solid #e5e5e5",
                      borderRadius: "8px",
                    }}
                    containerClass="mt-2"
                    onChange={(phone) => {
                      setPhoneErr("");
                      setLoginErr("");
                      setValue("phoneNumber", `+${phone}`);
                      setPhoneNumber(`+${phone}`);
                    }}
                  />
                  {formState.errors["phoneNumber"]?.message && (
                    <div
                      role="alert"
                      className="text-sm font-semibold text-red-500"
                    >
                      {formState.errors["phoneNumber"]?.message}
                    </div>
                  )}
                  {phoneErr && (
                    <div role="alert" className="text-sm font-semibold text-red-500">
                      {phoneErr}
                    </div>
                  )}
                </div>

                <div className="mt-2">
                  <Button
                    onClick={() => {
                      if (phoneNumber && phoneNumber.length === 13) {
                        setShowPasswordInput(true);
                        setPhoneErr("");
                      } else {
                        setPhoneErr("Phone number is invalid");
                      }
                    }}
                    className="w-full"
                  >
                    Next
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="mt-4">
                  Sign In
                  {phoneNumber && (
                    <div className="text-sm text-gray-900">Phone Number: {phoneNumber}</div>
                  )}
                </div>
                <InputField
                  type="password"
                  label="Password"
                  name="password"
                  passwordField={true}
                  error={formState.errors["password"]}
                  registration={register("password")}
                  onChange={(event) => {
                    setLoginErr("");
                    setValue("password", event.target.value);
                    setPassword(event.target.value);
                  }}
                />
                {loginErr && (
                  <div role="alert" className="text-sm font-semibold text-red-500">
                    {loginErr}
                  </div>
                )}
                <div>
                  <Button isLoading={isLoggingIn} type="submit" className="w-full">
                    Sign in
                  </Button>
                </div>
              </>
            )}
          </>
        )}
      </Form>

      <div className="relative mt-6">
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="w-full bg-gray-100 border border-gray-300 rounded-full py-2 px-4 flex justify-between items-center"
        >
          <span className="text-gray-700">Sign in with Socials</span>
          <span className={`transform ${dropdownOpen ? "rotate-180" : ""}`}>
            <ChevronDown />
          </span>
        </button>
        {dropdownOpen && (
          <div className="absolute w-full bg-white border border-gray-300 rounded-md shadow-lg mt-2">
            <button
              onClick={socialAuthHandlers.google}
              className="w-full flex items-center justify-start px-4 py-3 hover:bg-gray-100"
            >
              <FaGoogle className="text-red-700 mr-3" size={20} /> Google
            </button>
            <button
              onClick={socialAuthHandlers.linkedin}
              className="w-full flex items-center justify-start px-4 py-3 hover:bg-gray-100"
            >
              <FaLinkedin className="text-blue-700 mr-3" size={20} /> LinkedIn
            </button>
            <button
              onClick={socialAuthHandlers.github}
              className="w-full flex items-center justify-start px-4 py-3 hover:bg-gray-100"
            >
              <FaGithub className="text-black mr-3" size={20} /> GitHub
            </button>
            <button
              onClick={socialAuthHandlers.twitter}
              className="w-full flex items-center justify-start px-4 py-3 hover:bg-gray-100"
            >
              <FaTwitter className="text-blue-500 mr-3" size={20} /> Twitter
            </button>
            <button
              onClick={socialAuthHandlers.facebook}
              className="w-full flex items-center justify-start px-4 py-3 hover:bg-gray-100"
            >
              <FaFacebook className="text-blue-500 mr-3" size={20} /> Facebook
            </button>
          </div>
        )}
      </div>

      <button
        className="w-full mt-4"
        onClick={() => navigate("/auth/register")}
      >
        Don't have an account? <span className="text-primary-100">Sign Up</span>
      </button>
      <button
        className="w-full text-sm text-primary-100"
        onClick={() => navigate("/auth/forgot-password")}
      >
        Forgot Password?
      </button>
    </div>
  );
};

LoginForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};
